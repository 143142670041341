var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        { staticClass: "pl-3", attrs: { id: "certificatesList" } },
        [
          _c(
            "div",
            [
              _vm.mtlsneeded
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { color: "red" } },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-certificate-outline")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2862730050
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Your application needs at least one valid certificate"
                        ),
                      ]),
                    ]
                  )
                : _c("v-icon", [_vm._v("mdi-certificate-outline ")]),
              _c("span", { staticClass: "ml-1" }, [_vm._v("Certificates")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _vm.application.certificates.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("v-col", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(" No certificates available "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.application.certificates.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [_c("v-divider")],
                1
              )
            : _vm._e(),
          _vm.application.certificates.length != 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "104px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "7" },
                    },
                    [_c("strong", [_vm._v("Fingerprint/Status")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "2" },
                    },
                    [_c("strong", [_vm._v("Valid from")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "2" },
                    },
                    [_c("strong", [_vm._v("Valid to")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.application.certificates, function (certificate, index) {
            return [
              _c(
                "v-list-item",
                { key: index, attrs: { dense: "", selectable: false } },
                [
                  _c(
                    "v-list-item-icon",
                    { staticStyle: { "margin-right": "10px" } },
                    [
                      _c("v-icon", [
                        _vm._v(_vm._s(_vm.getStatusIcon(certificate.state))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { "text-align": "left" },
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getFingerprint(certificate)) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-4",
                              staticStyle: { "text-align": "left" },
                              attrs: { cols: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDateShort")(
                                      certificate.certNotBefore
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-1",
                              staticStyle: { "text-align": "left" },
                              attrs: { cols: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDateShort")(
                                      certificate.certNotAfter
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              disabled: _vm.dataLoading,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getOrSetCSR(
                                                  $event,
                                                  certificate
                                                )
                                              },
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCSRIcon(certificate.state)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getCSRIconTooltip(certificate.state)) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.getSignedCertDisabled(
                                                  certificate.state
                                                ) || _vm.dataLoading,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getOrSetSignedCert(
                                                  $event,
                                                  certificate
                                                )
                                              },
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getSignedCertIcon(
                                              certificate.state
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("p", {
                            staticClass: "mb-0",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getSignedCertIconTooltip(certificate.state)
                              ),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            id: "deleteCertificateButton_" + index,
                            disabled: _vm.dataLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteCertificate(
                                $event,
                                certificate.certifcateId
                              )
                            },
                          },
                        },
                        [_vm._v("mdi-trash-can-outline")]
                      ),
                    ],
                    1
                  ),
                  certificate.expiryNotification
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "no-focus",
                                            staticStyle: {
                                              "max-width": "24px",
                                              "max-height": "24px",
                                            },
                                            attrs: {
                                              icon: "",
                                              id:
                                                "notificationCertificateButton_" +
                                                index,
                                              disabled:
                                                !certificate.certNotBefore,
                                              loading: _vm.progessing,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showConfirmDialog(
                                                  $event,
                                                  certificate,
                                                  false
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "loader",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "custom-loader",
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-cached"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-alarm")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Expiry notification is activated. Click to deactivate."
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !certificate.expiryNotification
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "no-focus",
                                            staticStyle: {
                                              "max-width": "24px",
                                              "max-height": "24px",
                                            },
                                            attrs: {
                                              icon: "",
                                              id:
                                                "notificationCertificateButton_" +
                                                index,
                                              disabled:
                                                !certificate.certNotBefore,
                                              loading: _vm.progessing,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showConfirmDialog(
                                                  $event,
                                                  certificate,
                                                  true
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "loader",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "custom-loader",
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-cached"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-alarm-off")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Expiry notification is deactivated. Click to activate."
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider", { key: index + 100 }),
            ]
          }),
          _c(
            "v-list-item",
            { staticClass: "mt-5", attrs: { dense: "" } },
            [
              _c("v-spacer"),
              _c("div", [_vm._v("Add Certificate")]),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { disabled: !_vm.addCertDisabled(), bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "span",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        id: "addCertificateButton",
                                        disabled:
                                          _vm.addCertDisabled() ||
                                          _vm.dataLoading,
                                      },
                                      on: { click: _vm.addCertificate },
                                    },
                                    [_vm._v("mdi-plus-circle-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getDisabledToolTip()),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("csr-upload-overlay", {
        on: { close: _vm.closeCSRUpload },
        model: {
          value: _vm.csrupload,
          callback: function ($$v) {
            _vm.csrupload = $$v
          },
          expression: "csrupload",
        },
      }),
      _c("signed-cert-upload-overlay", {
        on: { close: _vm.closeSignedCert },
        model: {
          value: _vm.signedcertupload,
          callback: function ($$v) {
            _vm.signedcertupload = $$v
          },
          expression: "signedcertupload",
        },
      }),
      _c("certificate-create-overlay", {
        attrs: { appid: _vm.application.applicationId },
        on: { close: _vm.closeCertificateCreate },
        model: {
          value: _vm.createDialog,
          callback: function ($$v) {
            _vm.createDialog = $$v
          },
          expression: "createDialog",
        },
      }),
      _c("confirm-delete-overlay", {
        on: { confirm: _vm.onRealDeleteCertificate },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
      _c("expiry-notification-confirm", {
        ref: "expiryComponentCert",
        attrs: { mode: _vm.confirmNotificationMode },
        model: {
          value: _vm.confirmNotification,
          callback: function ($$v) {
            _vm.confirmNotification = $$v
          },
          expression: "confirmNotification",
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", color: "#006c27" },
          model: {
            value: _vm.showSnackError,
            callback: function ($$v) {
              _vm.showSnackError = $$v
            },
            expression: "showSnackError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackErrorMessage) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }