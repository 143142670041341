var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        { staticClass: "pl-3", attrs: { id: "redirectUriList" } },
        [
          _c(
            "div",
            [
              _vm.application.state == "MISSING_REDIRECTURI"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { color: "red" } },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-responsive")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4163161318
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Your application needs at least one valid redirectURI"
                        ),
                      ]),
                    ]
                  )
                : _c("v-icon", [_vm._v(" mdi-responsive ")]),
              _c("span", { staticClass: "ml-1" }, [_vm._v("Redirect URIs")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _vm.application.redirectURIs.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("v-col", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(" No URIs available "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.application.redirectURIs.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [_c("v-divider")],
                1
              )
            : _vm._e(),
          _vm._l(_vm.application.redirectURIs, function (redirect, index) {
            return [
              _c(
                "v-list-item",
                { key: index, attrs: { dense: "", selectable: false } },
                [
                  _c(
                    "v-list-item-icon",
                    { staticStyle: { "margin-right": "10px" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPlatformIcon(redirect.type)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(redirect.type) + " ")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "flex-nowrap" },
                        [
                          _c("v-col", [
                            _vm._v(" " + _vm._s(redirect.redirectURI) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { id: "deleteRedirectButton_" + index },
                          on: {
                            click: function ($event) {
                              return _vm.deleteRedirectURI(
                                $event,
                                redirect.redirectURIId
                              )
                            },
                          },
                        },
                        [_vm._v("mdi-trash-can-outline")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { key: redirect.redirectURIId }),
            ]
          }),
          _c(
            "v-list-item",
            { staticClass: "mt-5", attrs: { dense: "" } },
            [
              _c("v-spacer"),
              _c("div", [_vm._v("Add RedirectURI")]),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        disabled: !_vm.addRedirectDisabled(),
                        bottom: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "span",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        id: "addRedirectUriButton",
                                        disabled:
                                          _vm.addRedirectDisabled() ||
                                          _vm.dataLoading,
                                      },
                                      on: { click: _vm.addRedirectURI },
                                    },
                                    [_vm._v("mdi-plus-circle-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getDisabledToolTip()),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", color: "#006c27" },
          model: {
            value: _vm.showSnackError,
            callback: function ($$v) {
              _vm.showSnackError = $$v
            },
            expression: "showSnackError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackErrorMessage) + " ")]
      ),
      _c("redirect-overlay", {
        attrs: { application: _vm.application },
        model: {
          value: _vm.redirectDialog,
          callback: function ($$v) {
            _vm.redirectDialog = $$v
          },
          expression: "redirectDialog",
        },
      }),
      _c("confirm-delete-overlay", {
        on: { confirm: _vm.onRealDeleteRedirectURI },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }