var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        { staticClass: "pl-3", attrs: { id: "ownersList" } },
        [
          _c(
            "div",
            [
              _c("v-icon", [_vm._v(" mdi-security")]),
              _c("span", { staticClass: "ml-1" }, [
                _vm._v("IT Security By Design"),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _c(
            "v-list-item",
            { attrs: { dense: "", selectable: false } },
            [
              _c(
                "v-list-item-icon",
                { staticStyle: { "margin-right": "10px" } },
                [_c("v-icon", [_vm._v("mdi-link")])],
                1
              ),
              _c(
                "v-list-item-content",
                { staticStyle: { "text-align": "left" } },
                [
                  _vm.loading
                    ? _c("v-list-item-title", [
                        _vm._v(" processing request. Please wait. "),
                      ])
                    : _vm.application.itSecId == undefined ||
                      _vm.application.itSecId == null
                    ? _c("v-list-item-title", [
                        _vm._v(" No IT security demand configured"),
                      ])
                    : _vm.application.itSecId
                    ? _c("v-list-item-title", [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: {
                              href: _vm.application.itSecURL,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.application.itSecId) +
                                " - " +
                                _vm._s(_vm.application.itSecTitle)
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        { name: "blur", rawName: "v-blur" },
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.loading || _vm.dataLoading,
                                      },
                                      on: { click: _vm.selectSecId },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-plus-circle-outline")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Select the IT Security Demand of your application"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-icon",
                    {
                      directives: [{ name: "blur", rawName: "v-blur" }],
                      attrs: {
                        disabled:
                          _vm.application.itSecId == undefined ||
                          _vm.application.itSecId == null ||
                          _vm.loading ||
                          _vm.dataLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteSecId()
                        },
                      },
                    },
                    [_vm._v("mdi-trash-can-outline")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", color: "#006c27" },
          model: {
            value: _vm.showSnackError,
            callback: function ($$v) {
              _vm.showSnackError = $$v
            },
            expression: "showSnackError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackErrorMessage) + " ")]
      ),
      _c("confirm-delete-overlay", {
        on: { confirm: _vm.onRealDelete },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
      _c("i-t-sec-demand-overlay", {
        on: { selected: _vm.onItDemand },
        model: {
          value: _vm.selectDemand,
          callback: function ($$v) {
            _vm.selectDemand = $$v
          },
          expression: "selectDemand",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }